<template>
  <div>
    <vue-html2pdf
      ref="html2Pdf"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :filename="fileName"
      :pdf-quality="2"
      :manual-pagination="false"
      paper-size="a4"

      pdf-orientation="portrait"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
    >
      <section slot="pdf-content">
        <PDFLayout
          :invoice-data="invoiceData"
          :billing="billing"
          :transaction="transaction"
          :project="project"
          :loan-sub="loanSub"
        ></PDFLayout>
      </section>
    </vue-html2pdf>
    <!-- BATAS SUCI -->
    <div class="header-invoice">
      <v-row justify="space-between">
        <v-icon
          class="back-button"
          color="white"
          @click="$router.go(-1)"
        >
          {{ icons.mdiChevronLeft }}
        </v-icon>
        <h3 class="font-weight-bold mt-1">
          Detail Invoice
        </h3>
        <v-btn
          depressed
          color="white"
          class="text-capitalize cmd-button"
          outlined
          @click="downloadInvoice"
        >
          <v-icon
            dense
            color="white"
          >
            {{ icons.mdiDownload }}
          </v-icon>
          Download
        </v-btn>
      </v-row>
    </div>
    <v-row
      class="mt-10 mx-10"
      justify="space-between"
    >
      <div>
        <p>
          <v-img
            :src="project.logo.response.body.location"
            class="mt-3 mb-5"
            width="185"
          ></v-img> <span
            style="color:#1f979e;"
            class="font-weight-bold"
          >{{ project.name }}</span>
        </p>
        <div class="mt-7">
          <p
            class="font-weight-bold"
            style="color:black;margin-top:-10px"
          >
            Invoice number
          </p>
          <p style="margin-top:-18px">
            {{ billing.invoice.id }}
          </p>
        </div>
        <div class="mt-5">
          <p
            class="font-weight-bold"
            style="color:black;"
          >
            Date paid
          </p>
          <p style="margin-top:-18px">
            {{ billing.invoice.paid_at ? formatDate(billing.invoice.paid_at) : formatDate(billing.createdAt) }}
          </p>
        </div>
        <v-btn
          v-if="billing.invoice.status.toLowerCase() === 'pending' "
          dense
          class="paid-badge"
          style="background-color:#FCBE2D;color:white"
          target="_blank"
          :href="billing.invoice.invoice_url"
        >
          {{ billing.invoice.status }}
        </v-btn>
        <p
          v-if="billing.invoice.status.toLowerCase() ==='paid'"
          class="paid-badge"
          style="background-color:#29cc6a"
        >
          {{ billing.invoice.status }}
        </p>
        <p
          v-if="billing.invoice.status.toLowerCase() ==='expired'"
          class="paid-badge"
          style="background-color:#FF6666"
        >
          {{ billing.invoice.status }}
        </p>
      </div>
      <div class="mt-3">
        <p>From</p>
        <div>
          <p
            class="font-weight-bold mt-5"
            style="color:black;margin-top:-10px"
          >
            {{ project.name ? project.name : "--" }}
          </p>
          <p
            style="margin-top:-18px;max-width:250px"
            class="mt-3"
          >
            {{ project.address ? project.address : "--" }}
          </p>
        </div>
        <div class="mt-5">
          <p>
            Phone number: {{ project.channel.phone_number ? project.channel.phone_number : "--" }}
          </p>
          <p style="margin-top:-18px">
            Email: {{ project.email ? project.email : "--" }}
          </p>
        </div>
      </div>
      <div class="mt-3">
        <p>To</p>
        <div>
          <p
            class="font-weight-bold mt-5"
            style="color:black;margin-top:-10px"
          >
            {{ loanSub.loanSub.recipient.name ? loanSub.loanSub.recipient.name : "--" }}
          </p>
          <p
            style="margin-top:-18px;max-width:250px"
            class="mt-3"
          >
            {{ loanSub.loanSub.recipient.address ? loanSub.loanSub.recipient.address : "--" }}
          </p>
        </div>
        <div class="mt-5">
          <p>
            Phone number: {{ loanSub.loanSub.recipient.phone_number ? loanSub.loanSub.recipient.phone_number : "--" }}
          </p>
          <p style="margin-top:-18px">
            Email: {{ loanSub.loanSub.recipient.email ? loanSub.loanSub.recipient.email : "--" }}
          </p>
        </div>
      </div>
    </v-row>
    <div
      class="mt-10 mx-10 mb-10"
    >
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Name
              </th>
              <th class="text-left">
                QTY
              </th>
              <th class="text-right">
                Price
              </th>
              <th class="text-right">
                Subtotal
              </th>
            </tr>
          </thead>
          <tbody v-if="!loanSub.loanSub.transactionId">
            <tr>
              <td>{{ loanSub.loanTypeData.loanName ? loanSub.loanTypeData.loanName : "--" }}</td>
              <td>{{ "1" }}</td>
              <td class="text-right">
                {{ loanSub.loanSub.totalLoan ? formatToIDRCurrency(loanSub.loanSub.totalLoan) : "--" }}
              </td>
              <td class="text-right">
                {{ loanSub.loanSub.totalLoan ? formatToIDRCurrency(loanSub.loanSub.totalLoan) : "--" }}
              </td>
            </tr>
            <tr>
              <td>{{ "" }}</td>
              <td>{{ "" }}</td>
              <td class="text-right">
                {{ "Subtotal" }}
              </td>
              <td class="text-right">
                {{ formatToIDRCurrency(billing.amount) }}
              </td>
            </tr>
            <tr>
              <td>{{ "" }}</td>
              <td>{{ "" }}</td>
              <td class="text-right">
                {{ "Total" }}
              </td>
              <td class="text-right">
                {{ formatToIDRCurrency(billing.amount) }}
              </td>
            </tr>
          </tbody>
          <tbody v-if="loanSub.loanSub.transactionId">
            <tr class="flex align-right">
              <td>{{ transaction.propertyName }}</td>
              <td>{{ "1" }}</td>
              <td>{{ transaction.price }}</td>
              <td>{{ transaction.price }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-row
        justify="space-around"
        class="mt-10"
      >
        <v-col>
          <p>
            <span class="font-weight-bold">Notes: </span>{{ billing.description|| "Tidak ada deskripsi" }}
          </p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { ref } from '@vue/composition-api'
import { mdiChevronLeft, mdiDownload } from '@mdi/js'
import VueHtml2pdf from 'vue-html2pdf'
import moment from 'moment'
import PDFLayout from './PDFLayout.vue'

export default {
  name: 'DetailInvoice',
  setup() {
    const { isDark } = useAppConfig()
    const search = ref('')

    return {
      isDark,
      search,
      icons: {
        mdiChevronLeft,
        mdiDownload,
      },
    }
  },
  components: {
    VueHtml2pdf,
    PDFLayout,
  },
  data() {
    return {
      layoutTypes: [
        {
          type: 'example',
          component: 'PDFLayout',
          defaultStyle: {
            enableDownload: false,
            previewModal: true,
          },
        },
      ],
      layout: null,
      data: undefined,
      redirectUrl: null,

      invoiceData: null,
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
    idBilling() {
      return this.$route.params.idBilling
    },
    billing() {
      return this.$store.getters['manageLoan/getBillingById']
    },
    projects() {
      return this.$store.getters['manageProject/getProjects']
    },
    project() {
      return this.$store.getters['manageSalary/getCurrentProject']
    },
    loanSub() {
      return this.$store.getters['manageLoan/getLoanSub']
    },
    transaction() {
      return this.$store.getters['manageLoan/getTransaction']
    },
    fileName() {
      const text =
        this.formatDate(this.billing.invoice.paid_at) === 'Invalid date'
          ? this.formatDate(this.billing.createdAt)
          : this.formatDate(this.billing.invoice.paid_at)

      return `${this.project.name} ${text}`
    },
  },
  async created() {
    await this.getBillingById(this.idBilling)
    await this.$store.dispatch('manageProject/getProjects', { sub_id: this.user.sub_id })
    if (this.projects) {
      if (!this.project || !Object.keys(this.project).length) {
        this.$store.commit('manageSalary/setCurrentProject', this.projects[0])
      }
    }
    this.getLoanSubById()
    if (this.loanSub.loanSub.transactionId) {
      this.getUserTransaction()
    }
  },
  methods: {
    async getBillingById(id) {
      //console.log(id, 'id')
      await this.$store.dispatch('manageLoan/getBillingById', {
        sub_id: this.user.sub_id,
        idBilling: id,
      })
    },
    formatDate(date) {
      return this.$moment(new Date(date)).format('DD MMM YYYY HH:mm')
    },
    formatToIDRCurrency(number) {
      return number ? new Intl.NumberFormat('id-ID').format(number) : 0
    },
    async getLoanSubById() {
      try {
        await this.$store.dispatch('manageLoan/getLoanSubById', {
          idLoan: this.billing.loanId,
          sub_id: this.user.sub_id,
        })
      } catch (error) {
        console.log(error, 'errr')
      }
    },
    async getUserTransaction() {
      try {
        await this.$store.dispatch('manageLoan/getUserOrderingById', {
          transactionId: this.loanSub.loanSub.transactionId,
        })
      } catch (error) {
        console.log(error)
      }
    },
    downloadInvoice() {
      //console.log('🦆 ~ file: DetailInvoice.vue ~ line 370 ~ downloadInvoice ~ downloadInvoice')
      this.$refs.html2Pdf.generatePdf()
    },
    onProgress(progress) {
      this.loading = +progress
    },
  },
}
</script>

<style>
.header-invoice {
  background-color: #1f979e;
  padding-block: 15px;
  padding-inline: 30px;
  color: white;
  text-align: center;
}
.paid-badge {
  background-color: #29cc6a;
  width: 100px;
  text-align: center;
  color: white;
  padding-block: 4px;
  border-radius: 5px;
  font-size: 13px;
}
</style>
